<template>
	<div class="wrapper-center-vertical login">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 text-center">
					<img
						src="../../assets/images/itfa-logo.png"
						alt="Itfa"
						class="logo-login img-fluid"
					/>
				</div>
			</div>
			<b-row>
				<div class="col-12 col-md-4">
					<div class="d-flex align-items-center height-100">
						<btns-auth-social />
					</div>
				</div>
				<div class="col-12 col-md-4 hidden-in-md">
					<div class="d-flex justify-content-center py-5 height-100">
						<div class="separator-vertical"></div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<h5>{{ $t('sign_in_man') }}</h5>
					<b-form @submit.prevent="send">
						<div class="form-group">
							<b-form-input
								type="text"
								:placeholder="$t('username')"
								v-validate="'required|max:30'"
								:state="validateState('username')"
								v-model="login.username"
								name="username"
							/>
							<span class="error-inputs">{{ errors.first('username') }}</span>
						</div>
						<div class="form-group">
							<b-form-input
								type="password"
								:placeholder="$t('password')"
								v-validate="'required|min:6'"
								:state="validateState('password')"
								v-model="login.password"
								name="password"
							/>
							<span class="error-inputs">{{ errors.first('password') }}</span>
						</div>
						<div class="row align-items-center">
							<div class="col-6">
								<b-form-checkbox
									switch
									v-model="login.remember"
									name="check-button"
									>{{ $t('text_remenber') }}</b-form-checkbox
								>
							</div>
							<div class="col-6">
								<b-button
									variant="primary"
									:disabled="loading"
									block
									type="submit"
									>{{ $t('login') }}</b-button
								>
							</div>
						</div>
						<div class="row align-items-center mt-5">
							<div class="col-5">
								<b-link
									class="primary"
									:to="{ name: 'Register', params: { lang: lang } }"
									>{{ $t('btn_register') }}</b-link
								>
							</div>
							<div class="col-7 text-right">
								<b-link
									:to="{ name: 'RecoverPassword', params: { lang: this.lang } }"
									class="primary"
									>{{ $t('btn_lost_pass') }}</b-link
								>
							</div>
						</div>
					</b-form>
				</div>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import BtnsAuthSocial from './BtnsAuth.vue'

export default {
	components: {
		BtnsAuthSocial,
	},
	data() {
		return {
			login: {},
		}
	},
	computed: {
		...mapGetters(['loading', 'lang', 'console', 'isAuthenticated']),
	},
	mounted() {
		if (this.isAuthenticated) {
			this.$router.push({
				name: 'Dashboard',
				params: { lang: this.lang, console: this.console },
			})
		}
		document.title = `${this.$t('login')} - ITFA`
	},
	methods: {
		send() {
			this.$validator.validate().then((result) => {
				if (result) {
					const path = `${this.lang}/login`
					this.$axios.post(path, this.login).then((response) => {
						const data = response.data
						this.setUserAfterLogin(data, data.user.lang, this.console)
						// location.reload()
					})
				} else {
					this.showValidationsErrors(this.errors.items)
				}
			})
		},
	},
}
</script>
